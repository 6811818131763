<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-dark header-background">
      <div class="container">
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/t_logo.png" alt="Logo" class="logo" />
        </router-link>
        <span class="site-title d-none d-lg-block">
          <h4 class="mb-0"><strong>CO2-Rechner Beispielseite</strong></h4>
          <small>Test-Case "Vue"</small>
        </span>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav ms-auto">
            <router-link class="nav-item nav-link" :class="{ active: $route.path === '/' }" to="/">Home</router-link>
            <router-link class="nav-item nav-link" :class="{ active: $route.path === '/ueberProjekt' }" to="/ueberProjekt">Über das Projekt</router-link>
            <router-link class="nav-item nav-link" :class="{ active: $route.path === '/testcases' }" to="/testcases">Test-Cases</router-link>
            <router-link class="nav-item nav-link" :class="{ active: $route.path === '/mediaformats' }" to="/mediaformats">Medien und Formate</router-link>
            <router-link class="nav-item nav-link" :class="{ active: $route.path === '/kontakt' }" to="/kontakt">Kontakt</router-link>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header'
}
</script>

<style scoped>
.header-background {
  background-color: #10455B;
}
.site-title h4 {
  font-size: 1.5rem;
  margin-bottom: 0;
  color: white;
  line-height: 1.2;
}
.site-title small {
  display: block;
  font-size: 1rem;
  line-height: 1.2;
  color: white;
}
.navbar .navbar-nav .nav-link {
  color: white;
  margin-right: 15px;
}
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:focus {
  color: #FFAF20; /* Gelbton beim Überfahren */
}
.navbar .navbar-nav .active .nav-link,
.navbar .navbar-nav .nav-link.active {
  color: #6EC1E4; /* Blauton für geöffneten Link */
}
.logo {
  height: 90px;
}
@media (min-width: 992px) {
  .site-title {
    margin-left: 0px;
  }
}
</style>
