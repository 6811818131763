<template>
  <div class="container">
      <br />
      <h3><strong>Test-Case: Vue</strong></h3><br />
      <p>Sie befinden sich aktuell auf der Test-Website Vue, die als Teil unserer umfassenden CO2-Verbrauchsanalyse von Webtechnologien und Tools entwickelt wurde. Dieser Test-Case demonstriert, wie eine typische Info-Seite aufgebaut ist und dient uns als Referenz, um den Energieverbrauch und die CO2-Emissionen, die mit dem Betrieb einer solchen Seite verbunden sind, zu messen.</p><br />
      <div class="col">
        <div class="embed-responsive embed-responsive-16by9">
          <video class="embed-responsive-item" autoplay muted loop controls>
            <source src="@/assets/server.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <br/>
      <p><strong>Was ist Vue.js?</strong><br/>
        Vue.js ist ein JavaScript-Framework zur Entwicklung von Benutzeroberflächen und Single-Page-Anwendungen. Es ermöglicht die Erstellung von reaktiven, komponentenbasierten UIs mit einer einfachen und intuitiven Syntax.</p>
      <br />
      <router-link to="/mediaformats">Hier klicken</router-link>, um zur Seite „Medien und Formate“ zu gelangen. Diese ist ebenfalls für unsere Analyse relevant.
    </div>
</template>

<script>
export default {
  name: 'TestCases'
}
</script>

<style scoped>
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive-16by9 {
  padding-top: 56.25%;
}

.embed-responsive-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
