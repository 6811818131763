<template>
  <div id="app" style="margin: 0; color: #7A7A7A; font-family: 'Arial'; background-color: #F9F3ED;">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>


<script>
import Header from './components/header/Header.vue';
import Footer from './components/footer/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>



<style>
.text-center {
  text-align: center;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  padding: 50px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
</style>
