<template>
  <div class="container">
      <br />
      <h3><strong>Über das Projekt</strong></h3><br />
      <p>
        In unseren Test-Cases beobachten wir verschiedene Aspekte, darunter die Serverleistung,
        die Effizienz von Datenbankabfragen und die Größe von Website-Inhalten, die an den
        Browser des Benutzers gesendet werden. Wir vergleichen diese Daten mit anderen Technologien
        und Tools, um zu verstehen, wie dieser Test-Case im Vergleich abschneidet und welche
        Optimierungen möglicherweise für eine umweltfreundlichere Nutzung vorgenommen werden können.
      </p><br />
      <div class="col text-center">
        <img :src="serverImage" alt="Server-Architektur" class="img-fluid" />
      </div>
      <p>
        Als Besucher dieser Test-Website sind Sie ein Teil unserer Analyse. Jeder Klick und jede
        Interaktion liefert uns Daten, die wir nutzen können, um ein genaueres Bild vom
        Energieverbrauch dieser Technologie zu erhalten. Wir danken Ihnen für Ihre Unterstützung
        und Ihr Interesse an einer nachhaltigeren digitalen Welt.
      </p>
  </div>
</template>

<script>
export default {
  name: 'UeberProjekt',
  data() {
    return {
      serverImage: require('../assets/httpProtokoll.png')
    };
  }
}
</script>
