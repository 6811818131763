<template>
  <div class="container">
    <br>
      <h3><strong>Kontakt</strong></h3>

      <div class="row">
        <div class="col-md-4">
          <p>
            Dies ist nur eine Beispiel-Webseite, die das Hinzufügen eines Standorts für Test-Zwecke benötigt.<br /><br />
            Die hier angeführten <b>Kontaktinformationen existieren nicht!</b>
          </p>
          <p>
            Telnr: <u>+43 1112233444 </u><br />
            Mail: <u>gibtes.nicht@test.at</u>
          </p>
        </div>
        <div class="col-md-8">
          <iframe
              title="Standort"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2648.378904832503!2d15.59061561263797!3d48.410869571155175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4772844583b50efb%3A0xf28099fe9577643f!2sH%C3%B6here%20Technische%20Bundeslehranstalt%20(HTL)%20Krems!5e0!3m2!1sde!2sat!4v1705056445985!5m2!1sde!2sat"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <p><b>Beispiel-Kontaktformular</b></p>
          <form @submit.prevent="handleSubmit">
            <label for="vorname">Vorname</label>
            <input type="text" name="vorname" id="vorname" v-model="formState.vorname" required />

            <label for="nachname">Nachname</label>
            <input type="text" name="nachname" id="nachname" v-model="formState.nachname" required />

            <label for="email">E-Mail</label>
            <input type="email" name="email" id="email" v-model="formState.email" required />

            <label for="betreff">Betreff</label>
            <input type="text" name="betreff" id="betreff" v-model="formState.betreff" />

            <label for="nachricht">Deine Nachricht (optional)</label>
            <textarea name="nachricht" id="nachricht" v-model="formState.nachricht"></textarea>

            <input type="submit" value="Senden" /><br><br><br>
          </form>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Kontakt',
  data() {
    return {
      formState: {
        vorname: '',
        nachname: '',
        email: '',
        betreff: '',
        nachricht: ''
      }
    };
  },
  methods: {
    handleSubmit() {
      console.log('Formular gesendet mit:', this.formState);
    }
  }
};
</script>

<style scoped>
form {
  max-width: 400px;
  margin: 0 auto;
}

label {
  display: block;
  margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: transparent;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  border-color: #FFAF20;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type="submit"] {
  background-color: #00C2FF;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #FF8C00;
}
</style>
