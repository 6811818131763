<template>
  <div class="container">
    <br>
    <h3><strong>Medien und Formate</strong></h3>
    <p>Diese Seite existiert rein für Analyse-Zwecke. Warum finden Sie unter <router-link to="/ueberProjekt">Über das Projekt</router-link>.</p>

    <!-- Video Row -->
    <div class="row mb-4">
      <div class="col-md-6">
        <div class="embed-responsive embed-responsive-16by9">
          <video class="embed-responsive-item" autoplay muted loop controls>
            <source src="@/assets/waterfall.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="col-md-6">
        <div class="embed-responsive embed-responsive-16by9">
          <video class="embed-responsive-item" autoplay muted loop controls>
            <source src="@/assets/waterfall02.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="text-center mt-4">
          <b><p id="coole-zahl" class="coole-zahl">{{ cooleZahl }}</p></b>
          <p>Coole Zahl</p>
          <center><div class="swimmer-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" class="swimmer-svg">
              <circle cx="400" cy="400" r="390" stroke="#6EC1E4" stroke-width="4" fill="none" class="circle-bg"/>
              <path d="M189.61 310.58c3.54 3.26 15.27 9.42 34.39 9.42s30.86-6.16 34.39-9.42c16.02-14.77 34.5-22.58 53.46-22.58h16.3c18.96 0 37.45 7.81 53.46 22.58 3.54 3.26 15.27 9.42 34.39 9.42s30.86-6.16 34.39-9.42c14.86-13.71 31.88-21.12 49.39-22.16l-112.84-80.6 18-12.86c3.64-2.58 8.28-3.52 12.62-2.61l100.35 21.53c25.91 5.53 51.44-10.97 57-36.88 5.55-25.92-10.95-51.44-36.88-57L437.68 98.47c-30.73-6.58-63.02.12-88.56 18.38l-80.02 57.17c-10.38 7.39-19.36 16.44-26.72 26.94L173.75 299c5.47 3.23 10.82 6.93 15.86 11.58zM624 352h-16c-26.04 0-45.8-8.42-56.09-17.9-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C461.8 343.58 442.04 352 416 352s-45.8-8.42-56.09-17.9c-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C269.8 343.58 250.04 352 224 352s-45.8-8.42-56.09-17.9c-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C77.8 343.58 58.04 352 32 352H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h16c38.62 0 72.72-12.19 96-31.84 23.28 19.66 57.38 31.84 96 31.84s72.72-12.19 96-31.84c23.28 19.66 57.38 31.84 96 31.84s72.72-12.19 96-31.84c23.28 19.66 57.38 31.84 96 31.84h16c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zm-512-96c44.18 0 80-35.82 80-80s-35.82-80-80-80-80 35.82-80 80 35.82 80 80 80z" fill="#6EC1E4"/>
            </svg>
          </div></center>
          <p>Hover over it!</p>
          <div class="text-center mt-3">
            <h3 class="progress-title">Ein Balken</h3>
            <div class="progress-container">
              <div class="progress-bar" :style="{ width: progress + '%' }">
                <span>{{ progress }}%</span>
              </div>
            </div><br/><br/><br/>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h4>Kalender</h4>
        <h3 class="calendar-title">{{ month }} {{ year }}</h3>
        <table class="table calendar-table">
          <thead>
          <tr>
            <th>Mo</th>
            <th>Di</th>
            <th>Mi</th>
            <th>Do</th>
            <th>Fr</th>
            <th>Sa</th>
            <th>So</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(week, index) in totalWeeks" :key="index">
            <td v-for="(day, dayIndex) in 7" :key="dayIndex">
              {{ (index * 7 + dayIndex - adjustedFirstDayOfMonth + 1) > 0 && (index * 7 + dayIndex - adjustedFirstDayOfMonth + 1) <= daysInMonth ? (index * 7 + dayIndex - adjustedFirstDayOfMonth + 1) : '' }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaFormats',
  data() {
    return {
      cooleZahl: 0,
      progress: 0,
      currentDate: new Date(),
      month: '',
      year: 0,
      firstDayOfMonth: 0,
      adjustedFirstDayOfMonth: 0,
      daysInMonth: 0,
      totalDays: 0,
      totalWeeks: 0,
      totalSlots: 0,
      counterInterval: null,
      interval: null
    };
  },
  created() {
    this.month = this.currentDate.toLocaleString('default', { month: 'long' });
    this.year = this.currentDate.getFullYear();
    this.firstDayOfMonth = new Date(this.year, this.currentDate.getMonth(), 1).getDay() - 1;
    this.adjustedFirstDayOfMonth = this.firstDayOfMonth === -1 ? 6 : this.firstDayOfMonth;
    this.daysInMonth = new Date(this.year, this.currentDate.getMonth() + 1, 0).getDate();
    this.totalDays = this.firstDayOfMonth + this.daysInMonth;
    this.totalWeeks = Math.ceil(this.totalDays / 7);
    this.totalSlots = this.totalWeeks * 7;
  },
  mounted() {
    let count = 0;
    const endNumber = 14;
    const duration = 2000;
    const intervalTime = duration / endNumber;

    this.counterInterval = setInterval(() => {
      if (count < endNumber) {
        count++;
        this.cooleZahl = count;
      } else {
        clearInterval(this.counterInterval);
      }
    }, intervalTime);

    this.interval = setInterval(() => {
      this.progress = this.progress >= 86 ? this.progress : Math.min(this.progress + 1, 100);
    }, 20);
  },
  beforeUnmount() {
    clearInterval(this.counterInterval);
    clearInterval(this.interval);
  }
};
</script>



<style scoped>
#coole-zahl {
  color: #6EC1E4;
  font-size: 4rem;
}

.swimmer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background-color: transparent;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: relative;
}

.swimmer-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  z-index: -1;
}

.swimmer-svg {
  width: 100px;
  height: 100px;
}

.swimmer-container:hover::before {
  background-color: #6EC1E4;
}

.swimmer-svg .circle-bg {
  stroke: #6EC1E4;
  transition: stroke-width 0.3s, stroke 0.3s;
}

.swimmer-svg path {
  fill: #6EC1E4;
  transition: fill 0.3s;
}

.swimmer-svg:hover .circle-bg {
  stroke: #FFAF20;
  animation: pulseRing 1s infinite;
}

.swimmer-svg:hover path {
  fill: #FFAF20;
  animation: pulseSwimmer 1s infinite;
}

@keyframes pulseRing {
  0%, 100% { stroke-width: 4px; }
  50% { stroke-width: 6px; }
}

@keyframes pulseSwimmer {
  0%, 100% { fill-opacity: 1; }
  50% { fill-opacity: 0.8; }
}

.progress-container {
  background-color: #e6e6e6;
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
  height: 30px;
}

.progress-bar {
  width: 86%;
  height: 100%;
  background-color: #6EC1E4;
  border-radius: 3px 0 0 3px;
  text-align: right;
  padding-right: 10px;
  box-shadow: none;
  transition: width 2s;
}

.progress-bar span {
  color: white;
  font-weight: bold;
  line-height: 30px;
}

.progress-title {
  text-align: left;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #6EC1E4;
}

.calendar-table {
  background-color: transparent;
}

.calendar-table th, .calendar-table td {
  background-color: transparent;
}

.embed-responsive-item {
  max-width: 100%; /* Passt die Breite an den Eltern-Container an */
  max-height: 500px; /* Begrenzt die Höhe der Videos */
}
</style>
