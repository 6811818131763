<template>
  <div class="container">

      <br />
      <h3><strong>Willkommen bei der CO2-Verbrauchsanalyse von Webtechnologien</strong></h3><br />
      <p>Im Rahmen unserer Diplomarbeit haben wir uns einem Projekt gewidmet, das im Zeitalter der Digitalisierung und des Umweltbewusstseins von großer Bedeutung ist: die Analyse des CO2-Verbrauchs verschiedener Webtechnologien. Unser Ziel ist es, einen Einblick zu geben, wie unterschiedliche Technologien wie WordPress, PHP und andere beim Betrieb von Websites in Bezug auf ihre Umweltverträglichkeit abschneiden.</p><br />
      <div class="col">
        <center><img :src="natureImage" alt="Bild einer Natur" class="img-fluid" /></center>
      </div>
      <br/><br/>
    </div>

</template>

<script>
// Importieren Sie hier das Bild, ähnlich wie in React, aber achten Sie auf den relativen Pfad basierend auf Ihrem Projektstruktur
import Nature from '../assets/natur.jpg';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  data() {
    return {

      natureImage: Nature,
    };
  },
};
</script>

<style scoped>
/* Ihre CSS-Stile hier, oder importieren Sie eine CSS-Datei, wenn benötigt */
</style>
