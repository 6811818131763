import { createRouter, createWebHistory } from 'vue-router';
import Home from '../pages/Home.vue';
import UeberProjekt from '../pages/UeberProjekt.vue';
import TestCases from '../pages/TestCases.vue';
import MediaFormats from '../pages/MediaFormats.vue';
import Kontakt from '../pages/Kontakt.vue';

const routes = [
    { path: '/', component: Home },
    { path: '/UeberProjekt', component: UeberProjekt },
    { path: '/testcases', component: TestCases },
    { path: '/mediaformats', component: MediaFormats },
    { path: '/kontakt', component: Kontakt }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
