<template>
  <footer v-if="showFooter" class="footer-background">
    <div class="container text-center">
      <span class="text-white">© 2024 Vue Beispiel</span>
    </div>
  </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  data() {
    return {
      showFooter: false
    };
  },
  mounted() {
    this.checkFooter();
    window.addEventListener('resize', this.checkFooter);
  },
  unmounted() {
    window.removeEventListener('resize', this.checkFooter);
  },
  methods: {
    checkFooter() {
      const contentHeight = document.body.scrollHeight;
      const viewportHeight = window.innerHeight;
      this.showFooter = contentHeight <= viewportHeight;
    }
  }
};
</script>

<style scoped>
.footer-background {
  background-color: #10455B;
  color: white;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
</style>
